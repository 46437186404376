.detailCard {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: white;
  border-radius: 16px;
  height: 100%;
  color: #313B5E;
}

.titleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #DEDEDE;
  padding: 0.9rem 1.5rem;
}

.title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
}

.lgText {
  font-weight: 600;
  font-size: 2rem;
}

.maximize {
  display: flex;
  justify-content: flex-end;
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 1.2rem;
}

.labelContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
}

.label {
  display: flex;
  border-radius: 15px;
  align-items: center;
  justify-content: center;
  border: 1px solid #BDBDBD;
  padding: 0.3rem 1rem;
  font-size: 1.2rem;
  margin: 0.25rem;
}

.icon {
  height: 1.7rem;
  width: 1.7rem;
  margin-right: 0.25rem;
  color: #828282;
  stroke-width: 2px;
}

.iconBtn {
  background-color: transparent;
  border: none;
  padding: 0;
  box-shadow: none;
  margin-right: 1rem;
  color: #828282;
}

.smallIcon {
  height: 1.2rem;
  width: 1.2rem;
  margin-right: 0.2rem;
}

.decreaseText {
  color: #828282
}

.imagesWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  user-select: none;
}

.image {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: center;
  padding: 0.6rem 2.2rem;
  max-height: 30rem;
}

.chevronIconLeft {
  position: absolute;
  bottom: 1/2;
  height: 40px;
  z-index: 50;
  padding: 3px;
  left: 0;
  color: #BDBDBD
}

.chevronIconRight {
  position: absolute;
  bottom: 1/2;
  height: 40px;
  padding: 3px;
  z-index: 50;
  right: 0;
  color: #BDBDBD
}

.chevronIconRight:hover, .chevronIconLeft:hover {
  cursor: pointer;
}

.divider {
  width: 1px;
  display: flex;
  height: 100%;
  background-color: #DEDEDE;
  max-height: 10rem;
  align-self: center;
}

.pricesContainer {
  display: flex;
  flex: 1;
  justify-content: space-between;
  max-height: 12.5rem;
}

.priceColumn {
  display: flex;
  flex: 1;
  flex-direction: column;
  text-align: center;
  padding: 2rem 0rem;
}

.price {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}

.smallText {
  font-size: 1.2rem;
  font-weight: 400;
  align-items: center;
  display: flex;
  justify-content: center;
}

.footerColumn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footerTitle {
  font-size: 1.5rem;
  font-weight: 400;
  margin-bottom: 1rem;
}

.footerValue {
  font-weight: 600;
  margin-left: 0.25rem;
}

@media only screen and (min-width: 1920px) {
  .image {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: center;
    padding: 0.6rem 2.2rem;
    max-height: 45rem;
  }
}