.custom-date-picker-input .ant-picker-input input {
  @apply text-lg;
}

.custom-number-input .ant-input-number-input-wrap .ant-input-number-input {
  @apply text-lg p-0 h-auto;
}

.custom-button {
  border-radius: 15px;
  padding: 4px 10px;
  font-size: 1rem;
  background-color: white;
  box-shadow: none;
  border: 1px solid #bdbdbd;
  color: #313b5e;
  height: auto;
}

