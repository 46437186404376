.detailPreview {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem 1rem;
  border-radius: 18px;
  border: 1px solid  #BDBDBD;
  font-size: 1.25rem;
  font-weight: 500;
  min-width: 8rem;
}

.table {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.25rem;
  border-radius: 18px;
  font-size: 1.25rem;
  font-weight: 400;
  max-width: 10rem;
}

.sold {
  background-color: #D9D9D9;
  border: 1px solid  #828282;
  color: #828282;
}
.reserved {
  background-color: #FFFB9D;
  border: 1px solid  #8E8A1B;
  color: #8E8A1B;
}
.available {
  background-color: white;
  border: 1px solid  #828282;
  color: #828282;
}