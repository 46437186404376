/* TARGETS */

.container {
  @apply flex flex-col items-center justify-center;
}

.title {
  @apply font-bold capitalize xl:text-[1.25rem] 2xl:text-[1.5rem];
}

.wrapper {
  @apply flex items-center justify-center space-x-2;
}

.pieChart {
  @apply w-10 h-10;
}

.fontBold {
  @apply font-bold;
}

.targets {
  @apply relative flex flex-col space-y-4 flex-1 w-full h-full select-none;
}

.targetsHeaderContainer {
  @apply flex justify-between items-center;
}

.targetsHeader {
  @apply flex space-x-5 items-center font-bold;
}

.badge {
  @apply flex items-center bg-[#9EB9DA] rounded-full p-2 capitalize;
}

.icon {
  @apply stroke-2 w-10 h-10;
}

.maximize {
  @apply w-10 h-10 stroke-2 cursor-pointer
}

.chevronLeft {
  @apply absolute left-0 top-1/2 w-8 h-8 stroke-[3px] text-[#BDBDBD] cursor-pointer;
}

.chevronRight {
  @apply absolute right-0 top-1/2 w-8 h-8 stroke-[3px] text-[#BDBDBD] cursor-pointer;
}

.columns {
  @apply flex flex-1 items-center justify-evenly;
}

.addNew {
  @apply text-bmgray cursor-pointer hover:bg-gray-50 rounded-lg p-3 capitalize;
}

/* TARGETS SECTION */

.section {
  @apply flex flex-col space-y-8;
}

.sectionHeader {
  @apply flex justify-between items-center;
}

.sectionHeadersContent {
  @apply flex space-x-5 items-center font-bold;
}

.close {
  @apply w-10 h-10 stroke-[1px] text-[#BDBDBD] cursor-pointer;
}

.sectionRows {
  @apply grid auto-rows-auto space-y-2 max-w-fit;
}

.sectionColumns {
  @apply grid grid-cols-6 gap-6 border-b-[3px] pl-8 pb-2 border-[#F1F1F1];
}

.sectionColumn {
  @apply flex space-x-2 items-center;
}

.sectionColumnCentered {
  @apply flex space-x-2 items-center justify-center;
}

.sectionAddNew {
  @apply flex space-x-4 pl-8 py-2;
}

/* TARGET ROW */

.rowContainer {
  @apply relative grid grid-cols-6 gap-6 border-b pl-8 py-2 border-[#F1F1F1];
}

.rowFlex {
  @apply flex items-center;
}

.rowFlexSpaced {
  @apply flex items-center space-x-2;
}

.rowFlexSpacedEdit {
  @apply flex items-center space-x-2 cursor-pointer;
}

.rowFlexCentered {
  @apply flex items-center justify-center;
}

.rowFlexCenteredSpaced {
  @apply flex items-center justify-center space-x-2;
}

.rowDatePicked {
  @apply min-w-[115px] p-0;
}

.rowDeleteModal {
  @apply max-w-[60rem];
}

.rowDeleteModalContent {
  @apply flex flex-col flex-1 space-y-5;
}

.rowDeleteContainer {
  @apply relative grid grid-cols-5 gap-6 border-b pl-8 py-2 border-[#F1F1F1];
}

.rowBtnWrapper {
  @apply flex justify-center space-x-4;
}