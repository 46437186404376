.chevronIconLeft {
  position: absolute;
  top: 9rem;
  height: 40px;
  z-index: 50;
  padding: 3px;
  left: 0;
  color: #BDBDBD
}

.chevronIconRight {
  position: absolute;
  top: 9rem;
  height: 40px;
  padding: 3px;
  z-index: 50;
  right: 0;
  color: #BDBDBD
}

.chevronIconRight:hover, .chevronIconLeft:hover {
  cursor: pointer;
}

.imagesWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  user-select: none;
  width: 30rem;
  height: 21rem;
}

.image {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: center;
  padding: 0.6rem 2.2rem;
  max-height: 20rem !important;
  max-width: 25rem !important;
}

.imageSpace {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: center;
  padding: 0.6rem 2.2rem;
  height: 21rem !important;
}