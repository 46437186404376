.marketAnalysis {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  overflow: hidden;
  user-select: none;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  line-height: 36px;
  background-color: #fff;
  border-bottom: 1px solid #f0f0f0;
  border-top: 1px solid #f0f0f0;
  padding: 8px 50px;
  color: #313B5E;
  font-size: 14px;
  font-weight: 700;
}

.dataContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.icon {
  width: 24px;
  height: 24px;
  stroke-width: 2px;
}

.chevron {
  width: 20px;
  height: 20px;
  color: #BDBDBD;
  margin-left: 10px;
}

.download {
  display: flex;
  align-items: center;
  border-radius: 15px;
  font-size: 14px;
  font-weight: 700;
  padding: 0px 10px;
}

.downloadIcon {
  width: 20px;
  height: 20px;
  stroke-width: 2px;
  margin-right: 5px;
}

.canvaEmbed {
  display: flex;
  width: 100%;
  height: 100%;
  border: none;
}

.video {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}

.video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}