.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.content {
  display: flex;
  align-items: center;
  gap: 2rem;
  border: 1px solid #bdbdbd;
  border-radius: 16px;
  overflow: hidden;
  background-color: #fff;
  max-width: 1096px;
}

.img {
  width: auto;
  max-height: 40rem;
}

 .infoContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 1rem 3rem 1rem 0rem;
  row-gap: 2rem;
  text-align: left;
  color: #313B5E;
 }

 .badge {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #DEDEDE;
  border-radius: 15px;
  padding: 0.25rem 1.5rem;
  font-size: 1.25rem;
 }

 .title {
  font-size: 2.5rem;
  font-weight: 600;
 }

 .info {
  font-size: 1.25rem;
 }

 .readMore {
  display: flex;
  font-size: 1.25rem;
  gap: 0.4rem;
 }