.notificationsContainer {
  position: absolute;
  top: 10rem;
  right: 5rem;
  z-index: 25;
}

.notification {
  position: relative;
  background-color: white;
  border-radius: 16px;
  box-shadow: 0px 0px 25px rgba(0,0,0,0.25);
  display: flex;
  align-items: center;
  justify-content: start;
  color: #313B5E;
  padding: 10px 30px;
  margin-bottom: 10px;
}

.close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 13px;
  height: 13px;
  stroke-width: 5px;
  color: #BDBDBD;
  padding: 3px;
}

.close:hover {
  cursor: pointer;
  border-radius: 100%;
  background-color: #bdbdbd38;
}

.message {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  color: #313B5E;
}

.message:hover {
  cursor: pointer;
}

.title {
  font-size: 14.5px;
  font-weight: 700;
}

.text {
  font-size: 14.5px;
  font-weight: 400;
}