.bottomRightContainer {
  @apply absolute right-10 bottom-10 flex space-x-5 items-center;
}

.fullScreenBtn {
  @apply absolute right-10 top-10 drop-shadow-xl rounded-full border border-bmdarkgray h-16 w-16 bg-white flex items-center justify-center p-0;
}

.centerBtn {
  @apply drop-shadow-xl rounded-full border border-bmdarkgray h-16 w-16 bg-white flex items-center justify-center p-0;
}

.zoomBtnGrp {
  @apply inline-flex drop-shadow-xl rounded-[30px] overflow-hidden border border-bmdarkgray;
}

.mapTypeBtnGrp {
  @apply absolute left-10 bottom-10 inline-flex drop-shadow-xl rounded-[30px] overflow-hidden border border-bmdarkgray;
}

.zoomBtn {
  @apply border-none h-16 w-16 flex justify-center items-center p-0;
}

.mapBtn {
  @apply border-none h-16 w-32 flex justify-center items-center;
}

.icon {
  @apply text-bmgray w-8 h-8 stroke-[2px];
}