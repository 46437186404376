.container {
  position: relative;
  height: 30px;
}

.dropdown,
.dropdownDisabled {
  position: absolute;
  min-width: 13rem;
  max-width: 20rem;
  width: 100%;
  padding: 0px 10px;
  border-radius: 15px;
  border: 1px solid #bdbdbd;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  z-index: 99;
  user-select: none;
  line-height: 30px;
}

.dropdown {
  background-color: white;
  cursor: pointer;
  color: #313b5e;
}

.dropdownDisabled {
  background-color: #dbdbdb;
  cursor: not-allowed;
  color: #828282;
}

.button {
  display: flex;
  max-height: 30px;
  /* justify-content: space-evenly; */
  /* align-items: center; */
  white-space: nowrap;
  gap: 5px;
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;
}

.buttonContent {
  width: 100%;
}

.menu {
  border-top: 1px solid #bdbdbd;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 5px 0px;
}

.option {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 100%;
  border-radius: 8px;
}

.option:hover {
  background-color: #f2f3f4;
}

.checkboxes {
  border-bottom: 1px solid #bdbdbd;
  font-weight: normal;
  padding: 5px;
}

.unchecked {
  opacity: 0.7;
}

.downloadButton {
  display: flex;
  font-weight: bold;
  padding-left: 4px;
}
