/* PLAN */

.planContainer {
  @apply flex flex-col space-y-4 flex-1 w-full h-full select-none;
}

.planHeaderWrapper {
  @apply flex justify-between items-center px-[16px] pt-[24px];
}

.planTitleWrapper {
  @apply flex space-x-5 items-center font-bold capitalize;
}

.badge {
  @apply flex items-center h-14 w-14 bg-[#9EB9DA] rounded-full p-2;
}

.maximize {
  @apply w-10 h-10 stroke-2 cursor-pointer;
}

.planTableContainer {
  @apply grid grid-rows-3 xl:text-lg 2xl:text-xl;
}

.planTitleRow {
  @apply grid-cols-7 grid space-x-3 font-bold px-[16px] pb-2 text-center;
}

.planTableRowTop {
  @apply grid grid-cols-7 px-[16px] border-t-4 py-2 border-[#f8f8f8] text-center;
}

.planTableRowBottom {
  @apply grid grid-cols-7 px-[16px] border-t py-2 border-[#f8f8f8] text-center;
}

.icon {
  @apply stroke-2 w-10 h-10;
}

/* PLAN SECTION */

.sectionContainer {
  @apply flex flex-col space-y-8;
}

.sectionHeaderWrapper {
  @apply flex justify-between items-center;
}

.sectionTitleWrapper {
  @apply flex space-x-5 items-center font-bold capitalize;
}

.sectionWrapper {
  @apply grid auto-rows-auto max-w-[92%];
}

.sectionTableSelectors {
  @apply flex flex-1 justify-between items-center pl-8 font-bold select-none;
}

.sectionCompletionWrapper {
  @apply flex items-center space-x-8 capitalize;
}

.sectionRowFlexCenteredSpaced {
  @apply flex items-center space-x-2 cursor-pointer;
}

.sectionDatePickerWrapper {
  @apply flex items-center space-x-5;
}

.sectionDatePicker {
  @apply min-w-[120px] p-0;
}

.sectionSelectionControlsWrapper {
  @apply flex items-center space-x-8;
}

.sectionRowFlex {
  @apply flex items-center;
}

.sectionChevronDown {
  @apply text-[#BDBDBD] w-5 h-5 ml-2;
}

.sectionTablePeriodWrapper {
  @apply relative grid-cols-12 grid space-x-3 font-bold pl-8 pb-2 pt-6 text-center items-center select-none;
}

.sectionTableWrapper {
  @apply relative grid grid-cols-12 pl-8 border-t-4 py-2 border-[#F1F1F1] text-center;
}

.sectionTableRowTitle {
  @apply flex col-span-2 font-bold justify-between;
}

.sectionEditControls {
  @apply absolute -right-[2rem] flex space-x-2 items-center;
}

.sectionEditIcon {
  @apply w-10 h-10 text-bmgray stroke-2 cursor-pointer;
}

.sectionTableRowContainer {
  @apply grid grid-cols-12 pl-8 border-t py-2 border-[#F1F1F1] text-center bg-[#F8F8F8];
}

.sectionEditFooter {
  @apply flex flex-col flex-1 space-y-4 items-center justify-center;
}

.sectionEditBtns {
  @apply flex flex-1 space-x-10 items-center justify-center;
}

/* PLAN TABLE */