.container {
  @apply space-y-5 mb-[1.2rem];
}

.titleContainer {
  @apply flex justify-between;
}

.title {
  @apply font-semibold text-[2rem];
}